import React from "react"
import { About, Container } from "../components"

import Seo from "../components/seo"
const AboutPage = () => {
  return (
    <Container>
      <Seo
        title="クリニック紹介"
        description="より美しく、より若々しく、高いクオリティを求めるあなたへ。恵比寿から徒歩5分の非日常空間へぜひお越しください。恵比寿美容皮膚科では、お悩みや不安をかかえてご来院される患者様に対ししっかりとお話を伺ったうえで施術の方針を決定し納得いただけるまでご説明いたします。"
      />
      <About />
    </Container>
  )
}

export default AboutPage
